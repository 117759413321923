// src/components/auth/register.js


import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { Alert, AlertTitle, AlertDescription } from "../../components/ui/alert";
import axios from "../../axios";
import { Eye, EyeOff } from "lucide-react";


///on importe les images
import Logo from '../../images/logo-hippohub.webp'

function Register() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    accessCode: ""
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();






  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return "Le mot de passe doit contenir au moins 8 caractères";
    }
    if (!hasUpperCase || !hasLowerCase) {
      return "Le mot de passe doit contenir des majuscules et minuscules";
    }
    if (!hasNumbers) {
      return "Le mot de passe doit contenir au moins un chiffre";
    }
    if (!hasSpecialChar) {
      return "Le mot de passe doit contenir au moins un caractère spécial";
    }
    return "";
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateUsername = (username) => {
    if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
      return "L'identifiant ne peut contenir que des lettres, chiffres, tirets et underscores, aucun espace.";
    }
    return "";
  };




  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validation du code d'accès
    if (!formData.accessCode.trim()) {
      setError("Le code d'accès est requis");
      return;
    }

    // Validation du username
    const usernameError = validateUsername(formData.username);
    if (usernameError) {
      setError(usernameError);
      return;
    }

    // Validations existantes...
    if (!formData.username.trim()) {
      setError("L'identifiant est requis");
      return;
    }

    if (!formData.email || !validateEmail(formData.email)) {
      setError("Adresse email invalide");
      return;
    }

    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    try {
      setLoading(true);

      const response = await axios.post("/users/register", {
        username: formData.username.trim(),
        email: formData.email.trim(),
        password: formData.password,
        accessCode: formData.accessCode.trim()
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      // Gérer les erreurs de validation sans les afficher dans la console
      if (error?.response?.status === 400) {
        if (error.response?.data?.msg === 'Code d\'accès invalide ou expiré') {
          setError('Code d\'accès incorrect ou expiré. Veuillez contacter le support : ');
        } else {
          setError(error.response?.data?.msg || "Erreur lors de l'inscription");
        }
      } else {
        // Pour les autres types d'erreurs (500, etc.), on peut vouloir les logger
        // mais de manière discrète pour la production
        setError("Une erreur est survenue lors de l'inscription");
      }
    } finally {
      setLoading(false);
    }
  };




  // Fonction pour gérer le retour
  const handleBack = () => {
    navigate(-1); // Retourne à la page précédente
  };





  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">

        <div className="LogoHead">
          <img src={Logo} alt="logo hippohub" />

        </div>

        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">

          {/* Bouton Retour */}
          <div className="mb-4">
            <button
              type="button"
              onClick={handleBack}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
            >
              Retour
            </button>
          </div>

          <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">
            Inscription
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                Identifiant
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
                autoComplete="username"
              />
              <p className="mt-1 text-sm text-gray-500">
                Lettres, chiffres, tirets et underscores uniquement, aucun espace.
              </p>
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="email"
              >
                Adresse email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
                autoComplete="email"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="accessCode">
                Code d'accès
              </label>
              <input
                type="text"
                id="accessCode"
                name="accessCode"
                value={formData.accessCode}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <p className="mt-2 text-sm text-gray-600">
                L'accès à cette plate-forme est sécurisé par un code à usage unique. Pour obtenir un code,{' '}
                <Link to="/contact" className="text-blue-600 hover:text-blue-500">
                  contactez le support
                </Link>
                .
              </p>
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="password"
              >
                Mot de passe
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  autoComplete="new-password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center mt-1 text-gray-600 hover:text-gray-800"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="confirmPassword"
              >
                Confirmer le mot de passe
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  autoComplete="new-password"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center mt-1 text-gray-600 hover:text-gray-800"
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>




            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertTitle>Erreur</AlertTitle>
                <AlertDescription>
                  {error}
                  {error.includes('Code d\'accès incorrect ou expiré') && (
                    <span>
                      {' '}
                      <Link to="/contact" className="text-blue-600 hover:text-blue-500">
                        Contactez le support
                      </Link>
                    </span>
                  )}
                </AlertDescription>
              </Alert>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? "Inscription en cours..." : "S'inscrire"}
            </button>
          </form>

          <p>&nbsp;</p>



          <p className="mt-6 text-center text-sm text-gray-600">
            Déjà un compte?{" "}
            <Link
              to="/"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Connectez-vous
            </Link>
          </p>
        </div>

      </div>



    </>
  );
}

export default Register;
