// src/utils/axios.js
import axios from 'axios';

// Fonction pour vérifier si un token est expiré
const isTokenExpired = (token) => {
  if (!token) return true;
  
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = payload.exp * 1000; // Conversion en millisecondes
    return Date.now() >= expiryTime;
  } catch (error) {
    return true;
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Intercepteur pour les requêtes
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    
    // Vérifier si le token est expiré avant même d'envoyer la requête
    if (token && isTokenExpired(token)) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login?expired=true'; // Ajout d'un paramètre pour indiquer l'expiration
      return Promise.reject(new Error('Token expiré'));
    }

    if (token) {
      config.headers['x-auth-token'] = token;
    }

    return config;
  },
  (error) => {
    console.error('Erreur préparation requête:', {
      message: error.message,
      config: error.config
    });
    return Promise.reject(error);
  }
);

// Intercepteur pour les réponses
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorDetails = {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      url: error.config?.url,
      method: error.config?.method
    };

    if (error.code === 'ERR_NETWORK') {
      console.error('Erreur réseau :', errorDetails);
    } else if (error.response?.status === 401) {
      console.error('Erreur d\'authentification :', errorDetails);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      
      // Redirection avec un message différent selon le type d'erreur
      const isExpired = error.response?.data?.msg === "Token expiré";
      window.location.href = isExpired ? '/login?expired=true' : '/login?auth=failed';
    } else if (error.response?.status === 403) {
      console.error('Erreur d\'autorisation :', errorDetails);
    } else {
      console.error('Erreur Axios :', errorDetails);
    }

    return Promise.reject(error);
  }
);

export default instance;