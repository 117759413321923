// src/middleware/AuthMiddleware.js
//Vérifie si le JWT Token est expiré
//Si expiré, déconnecte l'utilisateur

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const isTokenExpired = (token) => {
  if (!token) return true;
  
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = payload.exp * 1000;
    return Date.now() >= expiryTime;
  } catch (error) {
    return true;
  }
};

export const AuthMiddleware = ({ children }) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      
      if (token && isTokenExpired(token)) {
        setShowAlert(true);
        setTimeout(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/', { replace: true });
        }, 3000); // Attendre 3 secondes avant la redirection
      }
    };

    checkToken();
    const interval = setInterval(checkToken, 60000);
    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <>
      {showAlert && (
        <div className="fixed top-4 right-4 z-50 w-96 bg-yellow-50 border border-yellow-200 rounded-lg p-4 shadow-lg">
          <div className="flex justify-between items-center">
            <p className="text-yellow-800">
              Votre session a expiré. Vous allez être redirigé vers la page de connexion.
            </p>
            <button 
              onClick={() => setShowAlert(false)}
              className="text-yellow-800 hover:text-yellow-900 ml-4"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      )}
      {children}
    </>
  );
};